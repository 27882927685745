import React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { PageProps } from 'gatsby';
import Layout, { LayoutOptions } from '../../components/Layout/Layout';
// import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Link from '../../components/Link/Link';
import RouteKeys from '../../../config/RouteKeys';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import './style.scss';

type Props = PageProps & WithTranslation;

const NotFoundPage: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('notFound.seo.title'),
    },
  };

  return (
    <Layout options={layoutOptions} className="NotFound">
      <div className="NotFound__container">
        <h1>{t('notFound.heading')}</h1>
        <p>
          {/* {t('notFound.description')} */}
          <Trans i18nKey="notFound.description" t={t}>
            <Link to={getPathFromRouteKey(RouteKeys.HOME)} />
          </Trans>
        </p>
        {/* <ButtonLink
          to={getPathFromRouteKey(RouteKeys.HOME)}
          styleOptions={{ isInline: true, invertColors: true }}
          linkOptions={{ shouldEnableActiveState: false }}
        >
          {t('notFound.backToHomeButton')}
        </ButtonLink> */}
      </div>
    </Layout>
  );
};

export default withTranslation()(NotFoundPage);
